import CreateOrderCommentUseCase from './CreateOrderCommentUseCase';
import CreateOrderDocument from './CreateOrderDocument';
import DeleteDocumentUseCase from './DeleteDocumentUseCase';
import GetCommentUseCase from './GetCommentUseCase';
import GetOrdersAllUseCase from './GetOrdersAllUseCase';
import GetOrdersListUseCase from './GetOrdersListUseCase';
import GetOrdersSubmissionUseCase from './GetOrdersSubmissionUseCase';
import GetUploadedDocumentsUseCase from './GetUploadedDocumentsUseCase';
import UpdateOrderUseCase from './UpdateOrderUseCase';

export default {
  CreateOrderCommentUseCase,
  CreateOrderDocument,
  DeleteDocumentUseCase,
  UpdateOrderUseCase,
  GetUploadedDocumentsUseCase,
  GetOrdersAllUseCase,
  GetOrdersListUseCase,
  GetOrdersSubmissionUseCase,
  GetCommentUseCase,
};
