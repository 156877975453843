import React, { lazy } from 'react';
import { Monitor, Users } from 'react-feather';

import {
  AdminUser,
  LeadAll,
  PackageImportIcon,
  DocumentSearchIcon,
} from 'presentation/components/icons';
import withCircle from 'presentation/HOCs/WithCircle';
import ImportCuratedCar from 'presentation/pages/car-insurance/CarManagement/ImportCuratedCar';
import CustomerMergeDetailPage from 'presentation/pages/car-insurance/CustomerMerge/CustomerMergeDetailPage';
import PackageComparisonPage from 'presentation/pages/car-insurance/PackageComparisonPage';
import PackageDetailPage from 'presentation/pages/car-insurance/PackageDetailPage';

import { getUserPermission } from './helper';
import { IRoutes } from './route.interface';
import {
  CRM_ROUTES,
  getPackageDetailUrl,
  getPackagesUrl,
  getPackageComparisonlUrl,
  getBrandImportUrl,
  getModelImportUrl,
} from './Urls';

import AuthLayout from '../layouts/Auth';
import DashboardLayout from '../layouts/Dashboard';
import NewDetailPageLayout from '../layouts/NewDetailPageLayout';
import PageLayout from '../layouts/Page';
import {
  healthCarePayRoutes,
  healthDiscountRoutes,
  healthRoutes,
  healthOrdersRoutes,
  healthPackageRoutes,
} from './healthRoutes';
import { travelOrdersRoutes } from './travelRoutes';

const LeadRejectionPage = lazy(
  () => import('../pages/car-insurance/leads/LeadRejectionPage')
);
const LeadAllPage = lazy(
  () => import('../pages/car-insurance/leads/LeadAllPage')
);
const LeadAssignmentPage = lazy(
  () => import('../pages/car-insurance/leads/LeadAssignmentPage')
);
// Auth components
const SignIn = lazy(() => import('../pages/admin/auth/SignIn'));
const Page404 = lazy(() => import('../pages/admin/auth/Page404'));
const Page500 = lazy(() => import('../pages/admin/auth/Page500'));

// Home
const Home = lazy(() => import('../pages/Home'));

// Leads
const LeadSourcePage = lazy(
  () => import('../pages/car-insurance/leads/LeadSourcePage/LeadSourcePageV2')
);

// Lead Page
const LeadPage = lazy(() => import('../pages/car-insurance/LeadDetailsPage'));
const OrderPage = lazy(() => import('../pages/car-insurance/OrderDetailPage'));
const CreatePaymentPage = lazy(
  () => import('../pages/car-insurance/CreatePaymentPage')
);
const CreateContractPage = lazy(
  () => import('../pages/car-insurance/CreateContractPage')
);
const CustomQuotePage = lazy(
  () => import('../pages/car-insurance/LeadDetailsPage/CustomQuote')
);
const PackageListingPage = lazy(
  () => import('../pages/car-insurance/PackageListingPage')
);
const PhoneBook = lazy(() => import('../pages/car-insurance/InsurerPhoneBook'));

// Customer Page
const CustomerPage = lazy(
  () => import('../pages/car-insurance/CustomerDetailsPage')
);

// Orders Pages
const OrdersAllPage = lazy(() => import('../pages/car-insurance/orders/All'));
const OrderDocumentsPage = lazy(
  () => import('../pages/car-insurance/orders/Documents')
);
const QCModulePage = lazy(
  () => import('../pages/car-insurance/orders/QCModule')
);
const OrderSubmissionPage = lazy(
  () => import('../pages/car-insurance/orders/Submission')
);
const OrderApprovalPage = lazy(
  () => import('../pages/car-insurance/orders/Approval')
);
const PrintingAndShipping = lazy(
  () => import('../pages/car-insurance/orders/PrintingAndShipping')
);
const ExportShipment = lazy(
  () => import('../pages/car-insurance/orders/ExportShipment')
);
const MyOrderPage = lazy(
  () => import('../pages/car-insurance/orders/MyOrders')
);

const MyLeadPage = lazy(() => import('../pages/car-insurance/myLeads'));

// Order Page
const SubmissionOrderPage = lazy(
  () =>
    import('../pages/car-insurance/OrderDetailPage/SubmissionOrderDetailPage')
);

// Order Page
const ApprovalOrderPage = lazy(
  () => import('../pages/car-insurance/OrderDetailPage/ApprovalOrderDetailPage')
);

// Printing And Shipping Order Page
const PrintingAndShippingOrderPage = lazy(
  () =>
    import(
      '../pages/car-insurance/OrderDetailPage/PrintingAndShippingOrderDetailPage'
    )
);

// New Order Page
const NewOrderPage = lazy(
  () => import('../pages/car-insurance/OrderDetailPage/NewOrderDetailPage')
);

// New QC Page
const QcDetailPage = lazy(
  () => import('../pages/car-insurance/OrderDetailPage/QcDetailPage')
);

// My Order Detail Page For Sales Agent
const MyOrderDetailPage = lazy(
  () => import('../pages/car-insurance/OrderDetailPage/MyOrderDetailPage')
);

// Order mass status update page
const OrderMassStatusUpdate = lazy(
  () => import('../pages/car-insurance/orders/ImportMassStatusChange')
);

// New Order Configs Page
const OrderConfigsPage = lazy(
  () => import('../pages/car-insurance/OrderDetailPage/ConfigsPage')
);

// Admin
const ImportLeadPage = lazy(
  () => import('../pages/car-insurance/leads/ImportLeadPage')
);
const UserPageV2 = lazy(() => import('../pages/admin/users/UserV2'));
const AutoAssignConfigPage = lazy(
  () => import('../pages/admin/AutoAssign/Configs')
);
const AutoAssignImportPage = lazy(
  () => import('../pages/admin/AutoAssign/Import')
);

const MassLeadImport = lazy(
  () => import('../pages/admin/AutoAssign/MassLeadImport')
);

// Admin Routes
const AdminTeamPage = lazy(() => import('../pages/admin/Team/newTeam'));

// Car Management Routes
const PackageImportPage = lazy(
  () => import('../pages/car-insurance/package/ImportPackagePage')
);
const SumInsuredCalculator = lazy(
  () => import('../pages/car-insurance/CarManagement/SumInsuredCalculator')
);

const ImportCarBrand = lazy(
  () => import('../pages/car-insurance/CarManagement/ImportCarBrand')
);

const ImportCarModel = lazy(
  () => import('../pages/car-insurance/CarManagement/ImportCarModel')
);

// Car Discount Routes
const CarDiscountImportPage = lazy(
  () => import('../pages/car-insurance/carDiscount/ImportCarDiscountPage')
);

// Customer Profile Routes
const CustomerProfileImportPage = lazy(
  () => import('../pages/car-insurance/CustomerProfile/ImportCustomerProfile')
);
const AllCustomerPage = lazy(
  () => import('../pages/car-insurance/CustomerProfile/All')
);

// Permission Denied
const PermissionDeniedPage = lazy(() => import('../pages/permissionDenied'));
const PackageSearchPage = lazy(
  () => import('../pages/car-insurance/package/packageSearch')
);

// Account Settings
const AccountSettingsPage = lazy(() => import('../pages/Account/Settings'));

// Discounts Page
const DiscountImportPage = lazy(
  () => import('../pages/car-insurance/Discounts/DiscountsImport')
);
const DiscountApprovalPage = lazy(
  () => import('../pages/car-insurance/Discounts/DiscountApprovalPage')
);
const DiscountVoucherPage = lazy(
  () => import('../pages/car-insurance/Discounts/Voucher')
);
const DiscountCampaignPage = lazy(
  () => import('../pages/car-insurance/Discounts/CampaignPage')
);

// Commission Report Page
const CommissionReprtPage = lazy(
  () => import('../pages/car-insurance/CommissionReportPage')
);

// Customer Merge Page
const CustomerMergePage = lazy(
  () => import('../pages/car-insurance/CustomerMerge/CustomerMergeListingPage')
);

// Cancellation All Page
const CancellationAllPage = lazy(
  () => import('../pages/car-insurance/OrderCancellation/All/index')
);

// Contract Listing Page
const ContractListingPage = lazy(
  () => import('../pages/car-insurance/CarePay/Contracts')
);

// Care Pay Transaction Page
const TransactionListingPage = lazy(
  () => import('../pages/car-insurance/CarePay/Transaction')
);

// Accounting Pages
const AccountingAllPage = lazy(
  () => import('../pages/car-insurance/Accounting/All')
);
const MassStatusPage = lazy(
  () => import('../pages/car-insurance/Accounting/MassStatus')
);

const presentationRoutes: IRoutes = {
  id: 'Home',
  path: '/',
  icon: <Monitor />,
  component: Home,
  children: null,
  layout: DashboardLayout,
  permission: getUserPermission('/'),
};

const accountRoutes: IRoutes = {
  id: 'Settings',
  path: '/account/settings',
  icon: <Monitor />,
  component: AccountSettingsPage,
  children: null,
  layout: AuthLayout,
  permission: getUserPermission('/account/settings'),
};

const leadsRoutes: IRoutes = {
  id: 'Leads',
  path: '/leads',
  name: 'menu.lead.root',
  icon: withCircle(LeadAll),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/leads'),
  children: [
    {
      id: 'leadsAssignment',
      path: '/leads/assignment',
      name: 'menu.lead.assignment',
      component: LeadAssignmentPage,
      sideBar: true,
      permission: getUserPermission('/leads/assignment'),
    },
    {
      id: 'leadsRejections',
      path: '/leads/rejection',
      name: 'menu.lead.rejections',
      component: LeadRejectionPage,
      sideBar: true,
      permission: getUserPermission('/leads/rejection'),
    },
    {
      id: 'leadsAll',
      path: '/leads/all',
      name: 'menu.lead.all',
      component: LeadAllPage,
      sideBar: true,
      permission: getUserPermission('/leads/all'),
    },
    {
      id: 'leadsImport',
      path: '/leads/import',
      name: 'menu.lead.import',
      component: ImportLeadPage,
      sideBar: true,
      permission: getUserPermission('/leads/import'),
    },
    {
      id: 'leadsSources',
      path: '/leads-settings/sources',
      name: 'menu.lead.sources',
      component: LeadSourcePage,
      sideBar: true,
      permission: getUserPermission('/leads-settings/sources'),
    },
  ],
};

const customerRoutes: IRoutes = {
  id: 'Customer',
  path: '/customers',
  name: 'Customer',
  layout: PageLayout,
  permission: getUserPermission('/customers'),
  children: [
    {
      path: '/customers/:id',
      name: 'Customer',
      component: CustomerPage,
      permission: getUserPermission('/customers/:id'),
    },
    {
      path: '/customers-merge/customers',
      name: 'Customer Merge',
      component: CustomerMergeDetailPage,
      permission: getUserPermission('/customers-merge/customers'),
    },
  ],
};

const leadRoutes: IRoutes = {
  id: 'Lead',
  path: '/leads',
  name: 'Lead',
  layout: PageLayout,
  permission: getUserPermission('/leads'),
  children: [
    {
      path: '/leads/my-leads',
      name: 'My Leads',
      component: MyLeadPage,
      permission: getUserPermission('/leads/my-leads'),
    },
    {
      path: '/leads/:id',
      name: 'Lead',
      component: LeadPage,
      permission: getUserPermission('/leads/:id'),
    },
    {
      path: '/leads/:id/create-payment',
      name: 'Create Payment',
      component: CreatePaymentPage,
      permission: getUserPermission('/leads/:id/create-payment'),
    },
    {
      path: '/leads/:id/create-contract',
      name: 'Create Contract',
      component: CreateContractPage,
      permission: getUserPermission('/leads/:id/create-contract'),
    },
    {
      path: '/leads/:id/custom-quote',
      name: 'Custom Quote Page',
      component: CustomQuotePage,
      permission: getUserPermission('/leads/:id/custom-quote'),
    },
    {
      path: getPackagesUrl(),
      name: 'Packages',
      component: PackageListingPage,
      permission: getUserPermission('/leads/:id/packages'),
    },
    {
      path: getPackageDetailUrl(),
      name: 'Package Detail',
      component: PackageDetailPage,
      permission: getUserPermission('/leads/:id/detail'),
    },
    {
      path: getPackageComparisonlUrl(),
      name: 'Package Comparison',
      component: PackageComparisonPage,
      permission: getUserPermission('/leads/:id/compare'),
    },
    {
      path: '/insurer-phonebook',
      name: 'Phone Book',
      component: PhoneBook,
      permission: getUserPermission('/leads/:id/detail'),
    },
  ],
};

const discountRoutes: IRoutes = {
  id: 'Discounts',
  path: '/discounts',
  name: 'menu.discounts.root',
  icon: withCircle(AdminUser),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/discounts'),
  children: [
    {
      id: 'discountsApproval',
      path: '/discounts/approval',
      name: 'menu.discounts.approval',
      component: DiscountApprovalPage,
      sideBar: true,
      permission: getUserPermission('/discounts/approval'),
    },
    {
      id: 'discountsImport',
      path: '/discounts/import',
      name: 'menu.discounts.importDiscount',
      component: DiscountImportPage,
      sideBar: true,
      permission: getUserPermission('/discounts/import'),
    },
    {
      id: 'discountsVoucher',
      path: CRM_ROUTES.DISCOUNT_VOUCHER,
      name: 'menu.discounts.voucher',
      component: DiscountVoucherPage,
      sideBar: true,
      permission: getUserPermission(CRM_ROUTES.DISCOUNT_VOUCHER),
    },
    {
      id: 'discountsCampaign',
      path: '/discounts/campaign',
      name: 'text.campaign',
      component: DiscountCampaignPage,
      sideBar: true,
      permission: getUserPermission('/discounts/campaign'),
    },
  ],
};

const ordersRoutes: IRoutes = {
  id: 'Orders',
  path: '/orders',
  name: 'menu.order.root',
  icon: withCircle(LeadAll),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/orders'),
  children: [
    {
      id: 'ordersAll',
      path: '/orders/all',
      name: 'menu.order.all',
      component: OrdersAllPage,
      sideBar: true,
      permission: getUserPermission('/orders/all'),
    },
    {
      id: 'ordersDocuments',
      path: '/orders/documents',
      name: 'menu.order.documents',
      component: OrderDocumentsPage,
      sideBar: true,
      permission: getUserPermission('/orders/documents'),
    },
    {
      id: 'ordersQC',
      path: '/orders/qc',
      name: 'menu.order.QC',
      component: QCModulePage,
      sideBar: true,
      permission: getUserPermission('/orders/qc'),
    },
    {
      id: 'ordersSubmission',
      path: '/orders/submission',
      name: 'menu.order.submission',
      component: OrderSubmissionPage,
      sideBar: true,
      permission: getUserPermission('/orders/submission'),
    },
    {
      id: 'ordersApproval',
      path: '/orders/approval',
      name: 'menu.order.approval',
      component: OrderApprovalPage,
      sideBar: true,
      permission: getUserPermission('/orders/approval'),
    },
    {
      id: 'ordersPrintingAndShipping',
      path: '/orders/shipment',
      name: 'menu.order.printingShipping',
      component: PrintingAndShipping,
      sideBar: true,
      permission: getUserPermission('/orders/shipment'),
    },
    {
      id: 'ordersExportShipment',
      path: '/orders/export-shipment',
      name: 'text.exportShipmentMenu',
      component: ExportShipment,
      sideBar: true,
      permission: getUserPermission('/orders/export-shipment'),
    },
    {
      id: 'ordersMassStatusUpdate',
      path: '/orders/mass-status-update',
      name: 'order.massAssign.massStatusChange',
      component: OrderMassStatusUpdate,
      sideBar: true,
      permission: getUserPermission('/orders/mass-status-update'),
    },
  ],
};

const adminRoutes: IRoutes = {
  id: 'Admin',
  path: '/admin',
  name: 'menu.userManagement.root',
  icon: withCircle(AdminUser),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/admin'),
  children: [
    {
      id: 'users',
      path: '/admin/users',
      name: 'menu.userManagement.users',
      component: UserPageV2,
      sideBar: true,
      permission: getUserPermission('/admin/users'),
    },
    {
      id: 'teams',
      path: '/admin/teams',
      name: 'menu.userManagement.teams',
      component: AdminTeamPage,
      sideBar: true,
      permission: getUserPermission('/admin/teams'),
    },
  ],
};

const autoAssignRoutes: IRoutes = {
  id: 'AutoAssign',
  path: '/auto-assign',
  name: 'menu.autoAssignment.root',
  icon: withCircle(AdminUser),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/auto-assign'),
  children: [
    {
      id: 'leadConfigs',
      path: '/auto-assign/configs',
      name: 'menu.autoAssignment.leadsConfig',
      component: AutoAssignConfigPage,
      sideBar: true,
      permission: getUserPermission('/auto-assign/configs'),
    },
    {
      id: 'autoAssignImport',
      path: '/auto-assign/import',
      name: 'menu.autoAssignment.leadsImport',
      component: AutoAssignImportPage,
      sideBar: true,
      permission: getUserPermission('/auto-assign/import'),
    },
    {
      id: 'autoAssignMassLeadImport',
      path: '/auto-assign/mass-lead-import',
      name: 'menu.autoAssignment.leadsMassAssign',
      component: MassLeadImport,
      sideBar: true,
      permission: getUserPermission('/auto-assign/mass-lead-import'),
    },
    {
      id: 'orderConfigs',
      path: '/auto-assign/order-configs',
      name: 'menu.autoAssignment.orderConfig',
      component: OrderConfigsPage,
      sideBar: true,
      permission: getUserPermission('/auto-assign/order-configs'),
    },
  ],
};

const packageRoutes: IRoutes = {
  id: 'Package',
  path: '/package',
  name: 'menu.package.root',
  icon: withCircle(PackageImportIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/package'),
  children: [
    {
      id: 'packageSearch',
      path: '/package/search',
      name: 'menu.package.search',
      component: PackageSearchPage,
      sideBar: true,
      permission: getUserPermission('/package/search'),
    },
    {
      id: 'packageImport',
      path: '/package/import',
      name: 'menu.package.import',
      component: PackageImportPage,
      sideBar: true,
      permission: getUserPermission('/package/import'),
    },
  ],
};

const authRoutes: IRoutes = {
  id: 'Auth',
  path: '/auth',
  icon: <Users />,
  name: 'Auth',
  layout: AuthLayout,
  permission: getUserPermission('/auth'),
  children: [
    {
      path: '/auth/sign-in',
      name: 'Sign In',
      component: SignIn,
      permission: getUserPermission('/auth/sign-in'),
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
      permission: getUserPermission('/auth/404'),
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
      permission: getUserPermission('/auth/500'),
    },
  ],
};

const orderRoutes: IRoutes = {
  id: 'Order',
  path: '/orders',
  name: 'Order',
  layout: PageLayout,
  permission: getUserPermission('/orders'),
  children: [
    {
      path: '/orders/my-orders',
      name: 'My Orders',
      component: MyOrderPage,
      permission: getUserPermission('/orders/my-orders'),
    },
    {
      path: '/orders/my-orders/:orderId',
      name: 'My Order',
      component: MyOrderDetailPage,
      permission: getUserPermission('/orders/my-orders/:orderId'),
    },
    {
      path: '/orders/:orderId',
      name: 'Order',
      component: OrderPage,
      permission: getUserPermission('/orders/:orderId'),
    },
    {
      path: '/orders/:orderId/policies/:policyId/submission',
      name: 'Submission Order',
      component: SubmissionOrderPage,
      permission: getUserPermission(
        '/orders/:orderId/policies/:policyId/submission'
      ),
    },
    {
      path: '/orders/:orderId/policies/:policyId/approval',
      name: 'Approval Order',
      component: ApprovalOrderPage,
      permission: getUserPermission(
        '/orders/:orderId/policies/:policyId/approval'
      ),
    },
    {
      path: '/orders/:orderId/policies/:policyId/printing-and-shipping',
      name: 'Printing And Shipping Order',
      component: PrintingAndShippingOrderPage,
      permission: getUserPermission(
        '/orders/:orderId/policies/:policyId/printing-and-shipping'
      ),
    },
  ],
};

const carDiscountRoutes: IRoutes = {
  id: 'CarDiscount',
  path: '/car-discount',
  name: 'menu.carDiscount.root',
  icon: withCircle(PackageImportIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/car-discount'),
  standAlone: true,
  children: [
    {
      id: 'carDiscountImport',
      path: '/car-discount/import',
      name: 'menu.carDiscount.root',
      component: CarDiscountImportPage,
      sideBar: true,
      permission: getUserPermission('/car-discount/import'),
    },
  ],
};

const customerProfileRoutes: IRoutes = {
  id: 'CustomerProfile',
  path: '/customer-profile',
  name: 'menu.customerProfile.root',
  icon: withCircle(AdminUser),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/customer-profile'),
  children: [
    {
      id: 'customerProfileAll',
      path: '/customer-profile/all',
      name: 'autoAssignOption.all',
      component: AllCustomerPage,
      sideBar: true,
      permission: getUserPermission('/customer-profile/all'),
    },
    {
      id: 'customerProfileImport',
      path: '/customer-profile/import',
      name: 'menu.customerProfile.import',
      component: CustomerProfileImportPage,
      sideBar: true,
      permission: getUserPermission('/customer-profile/import'),
    },
    {
      id: 'CustomerMergeAll',
      path: '/customers-merge/all',
      name: 'menu.customerMerge.all',
      component: CustomerMergePage,
      sideBar: true,
      permission: getUserPermission('/customers-merge/all'),
    },
  ],
};

const cancellationRoutes: IRoutes = {
  id: 'Cancellation',
  path: '/cancellation',
  name: 'menu.cancellation.root',
  icon: withCircle(DocumentSearchIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/cancellation'),
  children: [
    {
      id: 'cancellationAll',
      path: '/cancellation/all',
      name: 'menu.cancellation.all',
      component: CancellationAllPage,
      sideBar: true,
      permission: getUserPermission('/cancellation/all'),
    },
  ],
};

const accountingRoutes: IRoutes = {
  id: 'Accounting',
  path: '/accounting',
  name: 'menu.accounting.root',
  icon: withCircle(DocumentSearchIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/accounting'),
  children: [
    {
      id: 'AllAccounting',
      path: '/accounting/all',
      name: 'menu.accounting.all',
      component: AccountingAllPage,
      sideBar: true,
      permission: getUserPermission('/accounting/all'),
    },
    {
      id: 'Mass-status-change',
      path: '/accounting/mass-status',
      name: 'menu.accounting.mass-status-change',
      component: MassStatusPage,
      sideBar: true,
      permission: getUserPermission('/accounting/mass-status'),
    },
  ],
};

const permissionDeniedRoutes: IRoutes = {
  id: 'PermissionDenied',
  path: '/permission',
  name: 'Permission Denied',
  layout: PageLayout,
  permission: getUserPermission('/permission'),
  children: [
    {
      path: '/permission/denied',
      name: 'Permission Denied',
      component: PermissionDeniedPage,
      permission: getUserPermission('/permission/denied'),
    },
  ],
};

const newUIOrderRoutes: IRoutes = {
  id: 'New',
  path: '/new',
  name: 'New Order Page',
  layout: NewDetailPageLayout,
  permission: getUserPermission('/new'),
  children: [
    {
      path: '/orders/new/:orderId',
      name: 'Order',
      component: NewOrderPage,
      permission: getUserPermission('/orders/new/:orderId'),
    },
    {
      path: '/orders/qc/:orderId',
      name: 'QC',
      component: QcDetailPage,
      permission: getUserPermission('/orders/qc/:orderId'),
    },
  ],
};

const curatedCarRoutes: IRoutes = {
  id: 'CuratedCar',
  path: '/curated-car',
  name: 'menu.curatedCar.root',
  icon: withCircle(PackageImportIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/curated-car'),
  children: [
    {
      id: 'carBrandImport',
      path: getBrandImportUrl(),
      name: 'menu.curatedCar.brandImport',
      component: ImportCarBrand,
      sideBar: true,
      permission: getUserPermission('/car/brand-import'),
    },
    {
      id: 'carModelImport',
      path: getModelImportUrl(),
      name: 'menu.curatedCar.modelImport',
      component: ImportCarModel,
      sideBar: true,
      permission: getUserPermission('/car/model-import'),
    },
    {
      id: 'carImport',
      path: '/curated-car/import',
      name: 'menu.curatedCar.importSubmodel',
      component: ImportCuratedCar,
      sideBar: true,
      permission: getUserPermission('/curated-car/import'),
    },
    {
      id: 'carSumInsuredCalculator',
      path: '/package/sum-insured-calculator',
      name: 'menu.curatedCar.sumInsuredCalculator',
      component: SumInsuredCalculator,
      sideBar: true,
      permission: getUserPermission('/package/sum-insured-calculator'),
    },
  ],
};
const carePayRoutes: IRoutes = {
  id: 'CarePayListing',
  path: '/care-pay',
  name: 'menu.carePay.root',
  icon: withCircle(DocumentSearchIcon),
  layout: DashboardLayout,
  sideBar: true,
  permission: getUserPermission('/care-pay'),
  children: [
    {
      id: 'TransactionListing',
      path: '/care-pay/transactions',
      name: 'menu.carePay.transactionListing',
      component: TransactionListingPage,
      sideBar: true,
      permission: getUserPermission('/care-pay/transactions'),
    },
    {
      id: 'ContractListing',
      path: '/care-pay/contracts',
      name: 'menu.carePay.contractManagement',
      component: ContractListingPage,
      sideBar: true,
      permission: getUserPermission('/care-pay/contracts'),
    },
  ],
};

const commissionReportRoute: IRoutes = {
  id: 'CommissionReport',
  path: '/commission-report',
  layout: PageLayout,
  component: CommissionReprtPage,
  permission: getUserPermission('/commission-report'),
};

export const account = [accountRoutes];
export const dashboard = [
  leadsRoutes,
  ordersRoutes,
  adminRoutes,
  autoAssignRoutes,
  packageRoutes,
  presentationRoutes,
  carDiscountRoutes,
  customerProfileRoutes,
  orderRoutes,
  newUIOrderRoutes,
  commissionReportRoute,
  carePayRoutes,
  accountingRoutes,
];

export const healthSidebar = [
  healthRoutes,
  healthOrdersRoutes,
  healthPackageRoutes,
  adminRoutes,
  healthDiscountRoutes,
  healthCarePayRoutes,
];

export const travelSidebar = [travelOrdersRoutes];

export const sidebar = [
  leadsRoutes,
  ordersRoutes,
  adminRoutes,
  autoAssignRoutes,
  packageRoutes,
  curatedCarRoutes,
  carDiscountRoutes,
  customerProfileRoutes,
  discountRoutes,
  carePayRoutes,
  cancellationRoutes,
  accountingRoutes,
];

export const lead = [leadRoutes];
export const customer = [customerRoutes];
export const auth = [authRoutes];
export const permissionDenied = [permissionDeniedRoutes];
export const packages = [packageRoutes];
export const carDiscount = [carDiscountRoutes];
export const customerProfile = [customerProfileRoutes];
export const order = [orderRoutes];
export const newUI = [newUIOrderRoutes];
export const curatedCar = [curatedCarRoutes];
export const discount = [discountRoutes];
export const commission = [commissionReportRoute];
export const carePay = [carePayRoutes];
export const cancellation = [cancellationRoutes];
export const accounting = [accountingRoutes];

export const flattenRoutes = (
  routes: IRoutes[],
  collection: IRoutes[],
  hasPathLanguage = false
) => {
  const localesString = '/:locale(th|en)?';
  routes.forEach((route: IRoutes) => {
    if (!route.component && !route.children?.length) {
      return;
    }
    const { children, ...parent } = route;
    if (parent.component) {
      collection.push(parent);
    } else if (children?.length) {
      const childWithLayout = children.map((item: IRoutes) => ({
        ...item,
        path: hasPathLanguage ? `${localesString}${item.path}` : item.path,
        layout: item.layout || parent.layout,
      }));
      flattenRoutes(childWithLayout, collection);
    }
  });
  return collection;
};

export default [
  accountRoutes,
  authRoutes,
  leadsRoutes,
  adminRoutes,
  autoAssignRoutes,
  packageRoutes,
  permissionDeniedRoutes,
  packageRoutes,
  carDiscountRoutes,
  customerProfileRoutes,
  orderRoutes,
  newUIOrderRoutes,
  curatedCarRoutes,
  commissionReportRoute,
  cancellationRoutes,
  accountingRoutes,
];
