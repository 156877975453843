import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';

export const getPackagesUrl = (leadId?: string, isHealth = false) =>
  `${isHealth ? '/health' : ''}/leads/${leadId ?? ':id'}/packages`;

export const getPackageDetailUrl = (params?: {
  leadId?: string;
  packageId?: string | number;
  otherParams?: any;
  isHealth?: boolean;
}) => {
  const {
    leadId,
    packageId,
    otherParams = {},
    isHealth = false,
  } = params ?? {};
  const url = `${isHealth ? '/health' : ''}/leads/${leadId ?? ':id'}/detail`;

  const queryString = new URLSearchParams(
    _omitBy(
      {
        ...otherParams,
        id: packageId,
      },
      _isNil
    )
  ).toString();
  if (queryString) {
    return `${url}?${queryString}`;
  }
  return url;
};

export const getPackageComparisonlUrl = (params?: {
  leadId?: string;
  packageId?: string;
  otherParams?: any;
  isHealth?: boolean;
}) => {
  const {
    leadId,
    packageId,
    otherParams = {},
    isHealth = false,
  } = params ?? {};
  const url = `${isHealth ? '/health' : ''}/leads/${leadId ?? ':id'}/compare`;
  const queryString = new URLSearchParams(
    _omitBy(
      {
        ...otherParams,
        id: packageId,
      },
      _isNil
    )
  ).toString();
  if (queryString) {
    return `${url}?${queryString}`;
  }
  return url;
};

export const getBrandImportUrl = () => `/car/brand-import`;
export const getModelImportUrl = () => `/car/model-import`;

// define all the routes here
export enum CRM_ROUTES {
  DISCOUNT_VOUCHER = '/discounts/voucher',
}
