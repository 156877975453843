import _upperFirst from 'lodash/upperFirst';

import { INSURANCE_KIND } from 'presentation/components/InsurerInfoSection/InsurerInfoSection.helper';
import { getFilterValueFromStorage } from 'presentation/pages/car-insurance/PackageDetailPage/useGetPackageData';
import { getLanguage, LANGUAGES } from 'presentation/theme/localization';
import { InsuranceKind } from 'shared/types/insurers';
import { Lead } from 'shared/types/lead';
import {
  CarInsuranceType,
  CarRepairType,
  Package,
} from 'shared/types/packages';
import { formatSatangToBaht, satangToBahtNumber } from 'utils/currency';

import defaultFilterConfig from './filterConfig';
import {
  Deductible,
  FilterInterface,
  InsurerSumInsuranceRange,
} from './interface';

import { transformTitle } from '../packageTransformation/transformations';

export const defaultFiltervalue: FilterInterface = {
  sortBy: 'default',
  insuranceCategory: 'both',
  insuranceType: {
    'Type 1': false,
    'Type 2': false,
    'Type 3': false,
    'Type 2+': false,
    'Type 3+': false,
  },
  repairType: 'both',
  deductible: 'no_deductible',
  price: {
    min: 0,
    max: 100000,
  },
  sumInsured: {
    min: 0,
    max: 0,
  },
  insurer: {},
  isDefaultSumInsured: true,
};

type Insurer = {
  id: string;
  name: string;
  logo: string;
};

type SumInsured = {
  sumInsuredMin: number;
  sumInsuredMax: number;
};

const getInsurers = (insurers: Insurer[]) => {
  const defaultInsurerOrder = [
    'insurers/7',
    'insurers/27',
    'insurers/28',
    'insurers/35',
    'insurers/24',
    'insurers/19',
    'insurers/5',
    'insurers/11',
  ];

  const alphabeticalInsurers: Insurer[] = [];

  const fixedInsurers = insurers
    .filter((insurer) => {
      if (!defaultInsurerOrder.includes(insurer.id)) {
        alphabeticalInsurers.push(insurer);
        return false;
      }

      return true;
    })
    .sort(
      (a, b) =>
        defaultInsurerOrder.indexOf(a.id) - defaultInsurerOrder.indexOf(b.id)
    );

  alphabeticalInsurers.sort((a, b) => a.name.localeCompare(b.name));

  return [...fixedInsurers, ...alphabeticalInsurers].map((insurer) => ({
    key: insurer.id,
    label: insurer.name,
    logo: insurer.logo,
  }));
};

export const convertInsuranceType = (type: string) =>
  _upperFirst(type.replace('_', ' '));

export function getFilterConfig(
  insurancePackages: Package[],
  extra: {
    sumInsuredMinMax?: SumInsured;
  } = {}
) {
  const { sumInsuredMinMax } = extra;
  const config = defaultFilterConfig;

  const insurers: Insurer[] = [];
  const invoicePrices: number[] = [];

  insurancePackages.forEach((pkg) => {
    if (!insurers.some((insurer) => insurer.id === pkg.insuranceCompany.name)) {
      insurers.push({
        id: pkg.insuranceCompany.name,
        name: transformTitle(pkg, getLanguage() as LANGUAGES),
        logo: pkg.insuranceCompany.logo,
      });
    }
    invoicePrices.push(parseFloat(pkg.invoicePrice));
  });

  if (sumInsuredMinMax) {
    config.sumInsured.config.max = sumInsuredMinMax.sumInsuredMax;
    config.sumInsured.config.min = 0;
    config.sumInsured.config.step = 10000;
  }

  if (invoicePrices.length > 0) {
    const sortedInvoicePrices = invoicePrices
      .map((x) => BigInt(x))
      .sort((a, b) => Number(a - b));
    config.price.config.max = satangToBahtNumber(
      sortedInvoicePrices[sortedInvoicePrices.length - 1],
      'ceil'
    );
    config.price.config.min = satangToBahtNumber(
      sortedInvoicePrices[0],
      'floor'
    );
  }

  if (insurers.length > 0) {
    config.insurer.values = getInsurers(insurers);
  }

  return { config };
}

export const isPackageSelectedIsInstallmentApplicable = (
  lead: Lead | undefined
) => {
  const isValid = !(
    lead?.data?.checkout?.paymentOption !== 'FULL_PAYMENT' &&
    lead?.data?.checkout?.installments === 1
  );
  return isValid;
};

export const getDefaultPaymentOption = (
  lead: Lead,
  paymentOptions?: string[]
) => {
  if (lead?.data?.checkout?.paymentOption) {
    return lead.data.checkout.paymentOption;
  }

  if (paymentOptions?.length) {
    return paymentOptions[0];
  }

  return 'FULL_PAYMENT';
};

export function getDefaultValues(
  config: typeof defaultFilterConfig,
  lead: Lead,
  extra: {
    sumInsuredMinMax?: SumInsured;
  } = {}
) {
  const { sumInsuredMinMax } = extra;
  const existingValues = getFilterValueFromStorage();
  const { insuranceKind, voluntaryInsuranceType } = lead?.data ?? {
    voluntaryInsruanceType: [],
  };

  const defaultValues: FilterInterface = {
    ...defaultFiltervalue,
    sumInsured: {
      min: 0,
      max: sumInsuredMinMax?.sumInsuredMax ?? defaultFiltervalue.sumInsured.max,
    },
    price: {
      min: config.price.config.min,
      max: config.price.config.max,
    },
    insurer: config.insurer.values.reduce(
      (p, ins) => ({ ...p, [ins.key]: true }),
      {}
    ),
    insuranceCategory: insuranceKind,
    insuranceType: (voluntaryInsuranceType ?? []).reduce(
      (p, v) => ({ ...p, [convertInsuranceType(v) as CarInsuranceType]: true }),
      { ...defaultFiltervalue.insuranceType }
    ),
  };

  return { defaultValues: existingValues ?? defaultValues };
}

export const getTrueValues = (obj: Record<string, boolean>) => {
  if (obj) {
    return Object.keys(obj).filter((key) => obj[key]);
  }
  return [];
};

export const getInsuranceCategory = (
  includeVoluntary: boolean,
  includeCompulsory: boolean
) => {
  if (includeVoluntary && includeCompulsory) {
    return INSURANCE_KIND.BOTH;
  }
  if (includeCompulsory) {
    return INSURANCE_KIND.MANDATORY;
  }
  if (includeVoluntary) {
    return INSURANCE_KIND.VOLUNTARY;
  }
  return '' as INSURANCE_KIND;
};

export const decodeDeductible = (type: FilterInterface['deductible']) =>
  type === 'all_packages' ? ['only_deductible', 'no_deductible'] : [type];

export const encodeDeductible = (typeArr: string[]) =>
  typeArr.length === 1 ? (typeArr[0] as Deductible) : 'all_packages';

export const decodeRepairType = (type: FilterInterface['repairType']) =>
  type === 'both' ? ['Dealer', 'Garage'] : [type];

export const encodeRepairType = (typeArr: string[]) =>
  (typeArr.length === 1 ? typeArr[0] : 'both') as CarRepairType;

export const decodeInsuranceType = (
  types: Record<string, boolean>,
  insuranceKind: InsuranceKind
) => {
  const returnArr: string[] = [];
  if (insuranceKind === 'both' || insuranceKind === 'voluntary') {
    returnArr.push(...getTrueValues(types));
  }
  if (insuranceKind === 'both' || insuranceKind === 'mandatory') {
    returnArr.push('mandatory');
  }
  return returnArr;
};

export const encodeInsuranceType = (typeArr: string[]) => {
  let includeMandatory = false;
  let includeVoluntary = false;
  const initialValue = {
    'Type 1': false,
    'Type 2': false,
    'Type 3': false,
    'Type 2+': false,
    'Type 3+': false,
  };
  typeArr.forEach((type) => {
    if (type === 'mandatory') {
      includeMandatory = true;
    } else {
      includeVoluntary = true;
      initialValue[type as CarInsuranceType] = true;
    }
  });
  return [
    getInsuranceCategory(includeVoluntary, includeMandatory),
    initialValue,
  ] as [INSURANCE_KIND, typeof initialValue];
};

export const getInsuranceKind = (kind: string) => {
  switch (kind) {
    case 'voluntary':
      return 'VOLUNTARY';
    case 'mandatory':
      return 'MANDATORY';
    case 'both':
    default:
      return 'BOTH';
  }
};

export const injectSumInsuredRangeToInsurer = (
  insurers: { key: string; label: string; logo: string }[],
  insurerInsuranceRange: InsurerSumInsuranceRange
) =>
  insurers.map((insurer) => {
    const range = insurerInsuranceRange.find(
      (sir) => sir.insurer === insurer.key
    );
    return {
      ...insurer,
      adornment:
        range &&
        `${formatSatangToBaht(range?.sumInsuredMin)} - ${formatSatangToBaht(
          range?.sumInsuredMax
        )}`,
    };
  });
