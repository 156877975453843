const isCanCreateLead = (useRole: string) => {
  const rolesCreateLead = ['roles/admin'];
  return rolesCreateLead.includes(useRole);
};

const canDownload = (userRole: string) => {
  const rolesCreateLead = ['roles/admin', 'roles/backoffice-supervisor'];
  return rolesCreateLead.includes(userRole);
};

const leadImportColumns = [
  'First Name',
  'Last Name',
  'Gender',
  'Date of birth',
  'Phone',
  'Email',
  'Redbook ID',
  'Policy Start date',
];

const leadImportColumnsWithType = [
  { name: 'First Name', dataType: 'string' },
  { name: 'Last Name', dataType: 'string' },
  { name: 'Gender', dataType: 'gender' },
  { name: 'Date of birth', dataType: 'date' },
  { name: 'Phone', dataType: 'number' },
  { name: 'Email', dataType: 'email' },
  { name: 'Redbook ID', dataType: 'string' },
  { name: 'Policy Start date', dataType: 'date' },
];

const leadImportRequiredColumns = ['First Name', 'Phone'];

const leadImportMaximumLimit = 10000;

export {
  isCanCreateLead,
  canDownload,
  leadImportColumns,
  leadImportColumnsWithType,
  leadImportRequiredColumns,
  leadImportMaximumLimit,
};
