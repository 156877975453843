import { AnyObject } from 'yup';

import { basePaths, apiSlice, baseUrls } from 'data/slices/apiSlice';
import {
  Lead,
  LeadPaymentInformation,
  NewLeadPaymentInformation,
} from 'shared/types/lead';

import {
  LeadConnectPayload,
  CustomerResponse,
  UpdateLeadResponse,
} from './types';

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['PACKAGE_SELECTION', 'LEAD'],
});

const leadSlice = apiWithTags.injectEndpoints({
  endpoints: (build) => ({
    connectLeadToCustomer: build.mutation<CustomerResponse, LeadConnectPayload>(
      {
        query: ({ customerId, lead }) => ({
          url: `${basePaths.customer}/${customerId}/leads`,
          method: 'POST',
          body: { lead },
        }),
      }
    ),
    updateLead: build.mutation<
      UpdateLeadResponse,
      { leadId: string; data: AnyObject }
    >({
      query: ({ leadId, data }) => ({
        url: `${basePaths.lead}/${leadId}`,
        method: 'PATCH',
        body: { data },
      }),
    }),
    getLeadByID: build.query<Lead, string>({
      query: (leadName) => ({
        url: `${basePaths.lead}/leads/${leadName}`,
        method: 'GET',
      }),
      providesTags: ['PACKAGE_SELECTION', 'LEAD'],
    }),
    getNewLeadPaymentDetails: build.query<NewLeadPaymentInformation, string>({
      query: (leadName) => ({
        url: `${basePaths.financialtransaction}/leads/${leadName}/paymentDetails`,
        method: 'GET',
      }),
      providesTags: ['PACKAGE_SELECTION', 'LEAD'],
    }),
    getLeadPaymentDetails: build.query<LeadPaymentInformation, string>({
      query: (leadName) => ({
        url: `${baseUrls.goBff}/v1alpha1/leads/${leadName}:paymentDetails`,
        method: 'GET',
      }),
      providesTags: ['PACKAGE_SELECTION', 'LEAD'],
    }),
    getLeadContractDetails: build.query<LeadPaymentInformation, string>({
      query: (leadName) => ({
        url: `${baseUrls.goBff}/v1alpha1/leads/${leadName}:contractDetails`,
        method: 'GET',
      }),
      providesTags: ['PACKAGE_SELECTION', 'LEAD'],
    }),
    getBlackListStatus: build.query<{ isBlacklisted: boolean }, string>({
      query: (leadName) => ({
        url: `${baseUrls.goBff}/v1alpha1/leads/${leadName}/blacklistStatus`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useConnectLeadToCustomerMutation,
  useUpdateLeadMutation,
  useLazyGetLeadByIDQuery,
  useGetLeadByIDQuery,
  useGetLeadPaymentDetailsQuery,
  useGetNewLeadPaymentDetailsQuery,
  useGetLeadContractDetailsQuery,
  useGetBlackListStatusQuery,
} = leadSlice;
