import { getPaymentMethod } from 'data/slices/packageListing/helper';
import { getString } from 'presentation/theme/localization';
import { InsuranceKind } from 'shared/types/insurers';
import { Lead } from 'shared/types/lead';
import { Package, PackageSource } from 'shared/types/packages';

import { TransformedPackageType } from './hooks/useTransformedPackages';

export const NON_APPLICABLE_DEFAULT_INSTALLMENT = 1;
export const NON_APPLICABLE_DEFAULT_PAYMENT_OPTION = 'FULL_PAYMENT';

export function searchPackage(
  packageIds: string[],
  packages: Package[] | undefined
) {
  return (
    packages
      ?.filter((pkg) => packageIds.includes(pkg.name))
      .sort(
        (pkg1, pkg2) =>
          packageIds.indexOf(pkg1.name) - packageIds.indexOf(pkg2.name)
      ) ?? []
  );
}

export function isValidLead(lead: Lead | undefined) {
  return lead?.status !== 'LEAD_STATUS_PURCHASED';
}

export const getHeaderTitleByPackageSource = (packageSource: PackageSource) => {
  if (packageSource === 'custom') {
    return getString('packageListing.customQuote');
  }
  if (packageSource === 'manual') {
    return getString('packageListing.manualQuote');
  }
  if (packageSource === 'renewal_manual_quote') {
    return getString('packageListing.renewalPackage');
  }
  return '';
};

export const getApprovalStatus = (
  status?: 'APPROVED' | 'REJECTED' | 'PENDING' | 'APPROVAL_NOT_REQUIRED'
) => {
  switch (status) {
    case 'APPROVED':
      return {
        text: getString('text.approved'),
        variant: 'success',
      };
    case 'REJECTED':
      return {
        text: getString('text.rejected'),
        variant: 'muted',
      };
    case 'PENDING':
      return {
        text: getString('text.waitingApproval'),
        variant: 'warning',
      };
    case 'APPROVAL_NOT_REQUIRED':
    default:
      return {
        text: getString('text.noApprovalRequired'),
        variant: 'success',
      };
  }
};

interface PackageSelectParams {
  insuranceKind: InsuranceKind;
  sumInsuredMax?: number | string | undefined;
  sumInsuredMin?: number | string | undefined;
  paymentOption?: string;
  paymentMethod?: string;
  installment?: number;
}
interface SinglePackage extends PackageSelectParams {
  packages?: never;
  package?: string;
}

interface MultiPackage extends PackageSelectParams {
  package?: never;
  packages?: string[];
}

export function generateLendingApiPayload<
  T extends SinglePackage | MultiPackage,
>(rawPayload: T) {
  const packageId = {
    ...(Boolean(rawPayload.packages as T['packages']) && {
      packages: rawPayload.packages as T['packages'],
    }),
    ...(Boolean(rawPayload.package as T['package']) && {
      package: rawPayload.package as T['package'],
    }),
  } as { package: T['package']; packages: T['packages'] };

  return {
    ...packageId,
    insuranceKind: rawPayload.insuranceKind?.toUpperCase(),
    ...(Boolean(rawPayload?.sumInsuredMin) && {
      sumInsuredMin: rawPayload?.sumInsuredMin,
    }),
    ...(Boolean(rawPayload?.sumInsuredMax) && {
      sumInsuredMax: rawPayload?.sumInsuredMax,
    }),
    ...(Boolean(rawPayload.paymentOption && rawPayload.installment) && {
      paymentOption: rawPayload.paymentOption,
      paymentMethod:
        rawPayload.paymentMethod ?? getPaymentMethod(rawPayload.paymentOption),
      installmentPlan: rawPayload.installment,
    }),
  };
}

export interface CommonPayload extends PackageSelectParams {
  package: string;
}

export function generateDiscountPricingApiPayload<T extends CommonPayload[]>(
  rawPayload: T
) {
  return {
    filters: rawPayload.map(
      ({
        package: packageData,
        insuranceKind,
        sumInsuredMin,
        sumInsuredMax,
        paymentOption,
        installment,
        paymentMethod,
      }) => ({
        package: packageData,
        insuranceKind: insuranceKind?.toUpperCase(),
        ...(Boolean(sumInsuredMin) && {
          sumInsuredMin,
        }),
        ...(Boolean(sumInsuredMax) && {
          sumInsuredMax,
        }),
        ...(Boolean(paymentOption && installment) && {
          paymentOption,
          paymentMethod: paymentMethod ?? getPaymentMethod(paymentOption),
          installmentPlan: installment,
        }),
      })
    ),
  };
}

export type FilterData = ReturnType<
  typeof generateLendingApiPayload<PackageSelectParams>
>;

export type GeneratedApiReponse = ReturnType<
  typeof generateDiscountPricingApiPayload<CommonPayload[]>
>;

export const isPackageSelected = (
  packageData: TransformedPackageType,
  selectedPackage: string
) => Boolean(selectedPackage === packageData.id);

export const getApplicablePaymentInfo = ({
  isApplicablePackage,
  selectedPaymentOption,
  selectedInstallment,
}: {
  isApplicablePackage: boolean;
  selectedPaymentOption?: string;
  selectedInstallment?: number;
}) => {
  if (!isApplicablePackage) {
    return {
      installment: NON_APPLICABLE_DEFAULT_INSTALLMENT,
      paymentOption: NON_APPLICABLE_DEFAULT_PAYMENT_OPTION,
    };
  }
  return {
    installment: selectedInstallment,
    paymentOption: selectedPaymentOption,
  };
};
