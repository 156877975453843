import { get } from 'lodash';

import { addDays, format } from 'utils/datetime';

type FilterValueDateType = {
  criteria: string;
  range: {
    startDate: Date;
    endDate: Date;
  };
};

export type FilterMapType = {
  filter: string;
  type: string;
  field?: string;
  options?: any[];
  callback?: any;
};

export enum PhoneCountryCode {
  thai = '66',
}

const getFilters = (body: any, map: Array<FilterMapType>, encode = true) => {
  const filters: string[] = [];
  if (!body) {
    return filters;
  }
  map.forEach((filter: any) => {
    let filterValue: any | FilterValueDateType;
    if (filter.type === 'choiceDate') {
      filterValue = body[filter.filter];
    } else {
      filterValue = get(body, filter.filter, null);
    }

    filterValue =
      filterValue && typeof filterValue === 'string'
        ? filterValue.trim()
        : filterValue;

    switch (filter.type) {
      case 'contain':
        if (filterValue) {
          const value =
            filter.escape && encode
              ? encodeURIComponent(filterValue)
              : filterValue;
          filters.push(`${filter.field}:"${value}"`);
        }
        break;
      case 'match':
        if (
          filter &&
          filterValue !== '' &&
          filterValue !== 'all' &&
          filterValue !== null
        ) {
          const valueToUse =
            filterValue && typeof filterValue === 'object' && filter.callback
              ? filter.callback(filterValue)
              : null;

          let value =
            filter?.isPhone && filterValue.charAt(0) === '0'
              ? PhoneCountryCode.thai + filterValue.substring(1)
              : filterValue;

          const newValue = valueToUse || value;

          value =
            filter.escape && encode ? encodeURIComponent(newValue) : newValue;
          filters.push(
            `${filter.field}=${
              typeof value === 'string' ? `"${value}"` : value
            }`
          );
        }
        break;
      case 'choiceDate':
        filter.options.forEach((option: any) => {
          if (option.filter === filterValue?.criteria) {
            if (filterValue.range.startDate) {
              filters.push(
                `${
                  option.field
                }>="${filterValue.range.startDate.toISOString()}"`
              );
            }
            if (filterValue.range.endDate) {
              filters.push(
                `${option.field}<="${filterValue.range.endDate.toISOString()}"`
              );
            }
          }
        });
        break;
      case 'multi':
        if (filterValue && filterValue.length) {
          const items = filterValue.map((item: any) =>
            filter.callback ? filter.callback(item) : item
          );

          filters.push(`${filter.field} in ("${items.join('","')}")`);
        }
        break;
      case 'range':
        if (body[filter.filter]) {
          if (filterValue[1] !== 0) {
            filters.push(`${filter.field}>=${filterValue[0]}`);
            filters.push(`${filter.field}<=${filterValue[1]}`);
          }
        }
        break;
      case 'exact':
        if (
          filter &&
          filterValue !== '' &&
          filterValue !== 'all' &&
          filterValue !== null
        ) {
          filters.push(`${filter.field}="${filterValue}"`);
        }
        break;
      case 'notEqual':
        if (filterValue || filterValue === 0) {
          const value =
            filter.escape && encode
              ? encodeURIComponent(filterValue)
              : filterValue;
          filters.push(`${filter.field}!="${value}"`);
        }
        break;
      default:
    }
  });

  return filters;
};

export const buildFilter = (
  filterValues: any,
  map: FilterMapType[],
  decorators: any[] = [],
  encode = true
) => {
  let filters = getFilters(filterValues, map, encode);
  decorators.forEach((decorator) => {
    filters = decorator(filters);
  });

  return filters;
};

export const hasLeadStatusFilter = (filters: string[]) =>
  filters.some((filter: string) => filter.indexOf('lead.status') !== -1);

export const getQueryParts = (
  product: string,
  // eslint-disable-next-line default-param-last
  filters: string[] = [],
  pageSize: number,
  page: number,
  orderBy: string,
  policyDateEnabled?: boolean
) => {
  if (
    window.location.href.indexOf('leads/assignment') !== -1 &&
    ((filters && !hasLeadStatusFilter(filters)) || filters.length === 0)
  ) {
    filters.push('lead.status!="LEAD_STATUS_PURCHASED"');
  }

  if (window.location.href.indexOf('leads/rejection') !== -1) {
    filters.push(
      encodeURIComponent(
        'lead.isRejected=false rejections.decideTime="0001-01-01T00:00:00Z"'
      )
    );
  }

  if (
    policyDateEnabled &&
    (window.location.href.indexOf('leads/all') !== -1 ||
      window.location.href.indexOf('leads/assignment') !== -1)
  ) {
    filters.push(
      encodeURIComponent(
        `insurance.policyExpiryDate<="${format(
          addDays(new Date(), 90),
          'yyyy-MM-dd'
        )}" insurance.policyExpiryDate>="0001-01-01T00:00:00Z"`
      )
    );
  }

  if (
    window.location.href.indexOf('leads/my-leads') !== -1 ||
    window.location.href.indexOf('leads/assignment') !== -1
  ) {
    filters.push(encodeURIComponent('lead.isRejected!=true'));
  }

  const pageFrom = (page - 1) * pageSize;
  const queryParts = [`product=${product}`, `page_size=${pageSize}`];

  if (page > 1) {
    queryParts.push(`page_from=${pageFrom}`);
  }

  if (filters.length > 0) {
    queryParts.push(`filter=${filters.join(' ')}`);
  }

  if (orderBy) {
    queryParts.push(orderBy);
  }

  return queryParts;
};
