import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getMaximumPackageLimit } from './helper';

interface PackageListingState {
  packagesForComparison: string[];
}

const getPackageIdsFromUrl = () => {
  return (
    new URLSearchParams(window.location.search).get('id')?.split(',') ?? []
  );
};

const initialState = {
  packagesForComparison: getPackageIdsFromUrl(),
} as PackageListingState;

const packageListingSlice = createSlice({
  name: 'packageListing',
  initialState,
  reducers: {
    addToComparison(state, action: PayloadAction<string>) {
      const maximumPackageLimit = getMaximumPackageLimit();
      if (state.packagesForComparison.length < maximumPackageLimit) {
        state.packagesForComparison.push(action.payload);
      } else {
        state.packagesForComparison[
          maximumPackageLimit === 3 ? state.packagesForComparison.length - 1 : 1
        ] = action.payload;
      }
    },
    removeFromComparison(state, action: PayloadAction<string>) {
      state.packagesForComparison = state.packagesForComparison.filter(
        (id) => id !== action.payload
      );
    },
    resetComparison(state) {
      state.packagesForComparison = [];
    },
  },
});

export const { addToComparison, removeFromComparison, resetComparison } =
  packageListingSlice.actions;
export default packageListingSlice.reducer;
