import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  btn: {
    textTransform: 'uppercase',
  },
  card: {
    width: '100%',
  },
}));

export default useStyles;
