function createShadow(px: number) {
  return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

const shadows = [
  'none',
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  `0 7px 15px rgba(42, 49, 203, 0.1)`,
  `0 -7px 15px rgba(42, 49, 203, 0.1)`,
];

export default shadows;
