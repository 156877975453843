import Autocomplete from './Autocomplete/Autocomplete';
import ButtonRadio from './ButtonRadio';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import DateRange from './DateRange';
import DateRangeWithType from './DateRangeWithType';
import Input from './Input';
import KeyBoardDatePicker from './KeyBoardDatePicker';
import NumberInput from './NumberInput';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Slider from './Slider';
import Switch from './Switch';
import ToogleButton from './ToogleButton';
import TypeSelector from './TypeSelector';

import Button from '../Button';
import './Control.scss';

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  Button,
  Slider,
  TypeSelector,
  Autocomplete,
  DateRange,
  DateRangeWithType,
  ToogleButton,
  ButtonRadio,
  KeyBoardDatePicker,
  NumberInput,
  DatePicker,
  Switch,
};

export default Controls;
