import { CarSubModelImportAction } from 'presentation/redux/actions/carSubModel';
import { getString } from 'presentation/theme/localization';
import * as CONSTANTS from 'shared/constants';
import { formatDDMMYYYYHHMMSS } from 'shared/helper/utilities';
import { IAction } from 'shared/interfaces/common';
import { ILookUpUser } from 'shared/interfaces/common/admin/user';

import { initialState, updateTokenList } from '../carSubModelReducer.helper';

export const StatusImportedPackage = [
  { id: 1, title: 'importFileStatus.complete', value: 'COMPLETE' },
  { id: 2, title: 'importFileStatus.inProgress', value: 'IN_PROGRESS' },
  { id: 3, title: 'importFileStatus.error', value: 'ERROR' },
];

export const customImportedStatus = (status: string) => {
  const findImportedStatus = StatusImportedPackage.find(
    (item) => item.value === status
  );
  return getString(findImportedStatus?.title ?? '') ?? '';
};

export const formatCarSubModelImportedHistory = (
  listImportedPackage: any[] = [],
  userList: ILookUpUser[] = []
): any =>
  listImportedPackage.map((importedPackage) => {
    const createByUser = userList.find(
      (item: ILookUpUser) => item?.key === importedPackage.createBy
    );
    const downloadLink = `${process.env.VITE_API_ENDPOINT}/${CONSTANTS.apiUrl.carDiscount.getCarDiscountImportDownloadUrl}/${importedPackage.name}:generateDownloadUrl`;

    return {
      id: importedPackage.name,
      importStatus: customImportedStatus(importedPackage?.status),
      importDate: formatDDMMYYYYHHMMSS(importedPackage?.createTime),
      importFileName: importedPackage?.filename || '',
      importedBy: createByUser?.value || '-',
      errors: importedPackage.errors || [],
      downloadLink,
    };
  });

export default function listImportCarSubModelReducer(
  state = initialState,
  action: IAction<any>
): any {
  switch (action.type) {
    case CarSubModelImportAction.GET_CAR_SUB_MODEL_IMPORT: {
      const { currentPage, pageToken, pageSize, orderBy, showDeleted, filter } =
        action.payload;
      return {
        ...state,
        isFetching: true,
        listPageToken: updateTokenList(
          state.listPageToken,
          currentPage,
          pageToken
        ),
        pageIndex: currentPage,
        pageSize,
        orderBy,
        showDeleted,
        filter,
      };
    }
    case CarSubModelImportAction.GET_CAR_SUB_MODEL_IMPORT_SUCCESS: {
      const { importList, userList, pageToken } = action.payload;

      const formatList =
        formatCarSubModelImportedHistory(importList, userList) || [];
      const token = pageToken;
      return {
        ...state,
        data: formatList,
        isFetching: false,
        pageToken: token,
      };
    }
    case CarSubModelImportAction.GET_CAR_SUB_MODEL_IMPORT_FAILED: {
      return {
        ...state,
        data: [],
        isFetching: false,
        pageToken: '',
        success: false,
      };
    }
    default:
      return state;
  }
}
