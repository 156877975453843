export enum UserRoleID {
  Admin = 'roles/admin',
  Manager = 'roles/manager',
  Supervisor = 'roles/supervisor',
  SalesAgent = 'roles/sales',
  InboundAgent = 'roles/inbound',
  CustomerService = 'roles/customer-service',
  DocumentsCollection = 'roles/documents-collection',
  QualityControl = 'roles/quality-control',
  Submission = 'roles/submission',
  ProblemCase = 'roles/problem-case',
  BackOffice = 'roles/backoffice-supervisor',
  Shipment = 'roles/shipment-agent',
  Accounting = 'roles/accounting',
  CiAgent = 'roles/cash-installment-agent',
  CiSuperVisor = 'roles/cash-installment-supervisor',
}

export const RolesWithoutProduct = [
  UserRoleID.Admin,
  UserRoleID.Accounting,
  UserRoleID.InboundAgent,
  UserRoleID.CiAgent,
  UserRoleID.CiSuperVisor,
];

export const MY_LEADS_URL = '/leads/my-leads';
export const HEALTH_LEAD_URL = '/health/leads';
export const PERMISSION_DENIED_URL = '/permission/denied';
