import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Controls from 'presentation/components/controls/Control';
import { changeProductSelectorTypes } from 'presentation/redux/actions/typeSelector/globalProduct';
import { getString } from 'presentation/theme/localization';
import { Color } from 'presentation/theme/variants';
import ProductOptions from 'shared/constants/productOptions';

import { useGetUserSelector } from 'presentation/redux/selectors/user';
import { useAppSelector } from 'presentation/redux/hooks/typedHooks';
import { initialProduct } from 'presentation/redux/reducers/typeSelector/globalProduct';
import { useFlags } from 'flagsmith/react';
import FeatureFlags from 'config/flagsmithConfig';
import { UserRoleID, RolesWithoutProduct } from '../ProtectedRouteHelper';

function ProductTypeSelectorGlobal() {
  const dispatch = useDispatch();

  const user = useGetUserSelector();
  const globalProduct = useAppSelector(
    (state) => state.typeSelectorReducer.globalProductSelectorReducer.data
  );
  const userProduct = user?.product || globalProduct;

  const [value, setValue] = useState<string>(
    RolesWithoutProduct.includes(user?.role as UserRoleID)
      ? globalProduct
      : userProduct || initialProduct
  );
  useEffect(() => {
    if (!RolesWithoutProduct.includes(user?.role as UserRoleID)) {
      dispatch(changeProductSelectorTypes(userProduct));
    }
  }, [userProduct]);

  const featureFlags = useFlags([
    FeatureFlags.HT_56_ADD_HEALTH_TRAVEL_OPTION_DROPDOWN_20240521_TEMP,
  ]);

  const enableHealthTravelProductOptions =
    featureFlags[
      FeatureFlags.HT_56_ADD_HEALTH_TRAVEL_OPTION_DROPDOWN_20240521_TEMP
    ]?.enabled;

  const localeProducts = ProductOptions.filter(
    (option) =>
      !(option.value !== initialProduct && !enableHealthTravelProductOptions)
  ).map((prod) => ({
    ...prod,
    title: getString(prod.title),
  }));

  const localeProductsFilter =
    !RolesWithoutProduct.includes(user?.role as UserRoleID) && userProduct
      ? localeProducts.filter((prod) => prod.value === userProduct)
      : localeProducts;

  const handleChange = (e: React.ChangeEvent<any>) => {
    setValue(e.target.value);
    dispatch(changeProductSelectorTypes(e.target.value));
    window.location.href = '/';
  };

  return (
    <Controls.Select
      value={value}
      onChange={handleChange}
      selectField="value"
      options={localeProductsFilter}
      color={Color.BLUE_BOLD}
    />
  );
}

export default ProductTypeSelectorGlobal;
