import { Column } from 'presentation/components/modal/ImportModal/index.helper';

export const columns: Column[] = [
  {
    id: 'status',
    field: 'status',
    label: 'text.importStatus',
    minWidth: 100,
    sorting: 'none',
    disabled: true,
  },
  {
    id: 'createTime',
    field: 'createTime',
    label: 'text.importDate',
    minWidth: 100,
    sorting: 'desc',
  },
  {
    id: 'filename',
    field: 'filename',
    label: 'package.importFileName',
    minWidth: 100,
    sorting: 'none',
    noTooltip: false,
    disabled: true,
  },
  {
    id: 'createBy',
    field: 'createBy',
    label: 'text.importedBy',
    minWidth: 100,
    sorting: 'none',
    disabled: true,
  },
  {
    id: 'packageType',
    field: 'packageDetails.packageType',
    label: 'text.packageType',
    minWidth: 100,
    sorting: 'none',
    disabled: true,
  },
  {
    id: 'imported',
    field: 'imported',
    label: 'text.numberOfPackages',
    minWidth: 100,
    sorting: 'none',
    disabled: true,
  },
];

export const StatusImportedPackage = [
  { id: 1, title: 'importFileStatus.complete', value: 'COMPLETE' },
  { id: 2, title: 'importFileStatus.inProgress', value: 'IN_PROGRESS' },
  { id: 3, title: 'importFileStatus.error', value: 'ERROR' },
];

export const mandatoryPackageTemplate = [
  'name',
  'insurance_company_id',
  'oic_code',
  'personal_accident_coverage',
  'personal_accident_coverage_no',
  'medical_expenses_coverage',
  'medical_expenses_coverage_no',
  'liability_per_person_coverage',
  'liability_per_accident_coverage',
];

export const mandatoryPackageRequireColumn = [
  'name',
  'insurance_company_id',
  'oic_code',
  'personal_accident_coverage',
  'personal_accident_coverage_no',
  'medical_expenses_coverage',
  'medical_expenses_coverage_no',
  'liability_per_person_coverage',
  'liability_per_accident_coverage',
];

export const mandatoryPackageTemplateWithDatatype = [
  { name: 'name', dataType: 'string' },
  { name: 'insurance_company_id', dataType: 'number' },
  { name: 'oic_code', dataType: 'number' },
  { name: 'personal_accident_coverage', dataType: 'number' },
  { name: 'personal_accident_coverage_no', dataType: 'number' },
  { name: 'medical_expenses_coverage', dataType: 'number' },
  { name: 'medical_expenses_coverage_no', dataType: 'number' },
  { name: 'liability_per_person_coverage', dataType: 'number' },
  { name: 'liability_per_accident_coverage', dataType: 'number' },
];

export const mandatoryPackageMaximumUpload = 100;

export const renewalPackageTemplate = [
  'chassis_no',
  'insurer_id',
  'last_policy_expiration_date',
  'oic_code',
  'car_insurance_type',
  'car_repair_type',
  'liability_property_coverage',
  'liability_per_person_coverage',
  'liability_per_accident_coverage',
  'sum_coverage',
  'fire_theft_coverage',
  'flood_coverage',
  'personal_accident_coverage',
  'personal_accident_coverage_no',
  'medical_expenses_coverage_no',
  'medical_expenses_coverage',
  'bail_bond_coverage',
  'deductible_amount',
  'net_premium',
  'gross_premium',
  'insurer_accept',
];

export const renewalPackageRequireColumnAccepted = [
  'chassis_no',
  'insurer_id',
  'last_policy_expiration_date',
  'oic_code',
  'car_insurance_type',
  'car_repair_type',
  'liability_property_coverage',
  'liability_per_person_coverage',
  'liability_per_accident_coverage',
  'sum_coverage',
  'fire_theft_coverage',
  'flood_coverage',
  'personal_accident_coverage',
  'personal_accident_coverage_no',
  'medical_expenses_coverage_no',
  'medical_expenses_coverage',
  'bail_bond_coverage',
  'deductible_amount',
  'net_premium',
  'gross_premium',
  'insurer_accept',
];

export const renewalPackageRequireColumnNotAccepted = [
  'chassis_no',
  'insurer_id',
  'insurer_accept',
];

export const renewalPackageTemplateWithDatatype = [
  { name: 'chassis_no', dataType: 'string|number' },
  { name: 'insurer_id', dataType: 'number' },
  { name: 'last_policy_expiration_date', dataType: 'string' },
  { name: 'oic_code', dataType: 'number' },
  { name: 'car_insurance_type', dataType: 'insuranceType' },
  { name: 'car_repair_type', dataType: 'string' },
  { name: 'liability_property_coverage', dataType: 'number' },
  { name: 'liability_per_person_coverage', dataType: 'number' },
  { name: 'liability_per_accident_coverage', dataType: 'number' },
  { name: 'sum_coverage', dataType: 'number' },
  { name: 'fire_theft_coverage', dataType: 'number' },
  { name: 'flood_coverage', dataType: 'number' },
  { name: 'personal_accident_coverage', dataType: 'number' },
  { name: 'personal_accident_coverage_no', dataType: 'number' },
  { name: 'medical_expenses_coverage_no', dataType: 'number' },
  { name: 'medical_expenses_coverage', dataType: 'number' },
  { name: 'bail_bond_coverage', dataType: 'number' },
  { name: 'deductible_amount', dataType: 'number' },
  { name: 'net_premium', dataType: 'number' },
  { name: 'gross_premium', dataType: 'number' },
  { name: 'insurer_accept', dataType: 'zeroOneBool' },
];
