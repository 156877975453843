import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Loader from 'presentation/components/Loader';
import AuthLayout from 'presentation/layouts/Auth';
import Page404 from 'presentation/pages/admin/auth/Page404';
import { useAppSelector } from 'presentation/redux/hooks/typedHooks';
import { PRODUCTS } from 'config/TypeFilter';
import { useGetAuthenticateQuery } from 'data/slices/authSlice';

import { IRoutes } from './route.interface';

import ProtectedRoute from '../components/ProtectedRoute';
import PublicRoute from '../components/PublicRoute';

import {
  account as accountRoutes,
  dashboard as dashboardRoutes,
  auth as authRoutes,
  lead as leadRoutes,
  customer as customerRoutes,
  permissionDenied,
  flattenRoutes,
  packages as packageRoutes,
  carDiscount as carDiscountRoutes,
  order as orderRoutes,
  cancellation as cancellationRoutes,
  customerProfile as customerProfileRoutes,
  curatedCar as curatedCarRoutes,
  discount as discountRoutes,
  carePay as carePayRoutes,
  accounting as accountingRoutes,
} from './index';
import {
  healthCarePayRoutes,
  healthDiscountRoutes,
  healthRoutes,
  healthOrdersRoutes,
  healthPackageRoutes,
} from './healthRoutes';
import { travelOrdersRoutes } from './travelRoutes';
import {
  RolesWithoutProduct,
  UserRoleID,
} from 'presentation/components/ProtectedRouteHelper';

function AppRoutes() {
  const { data: user } = useGetAuthenticateQuery();

  const product = useAppSelector(
    (state) => state.typeSelectorReducer.globalProductSelectorReducer.data
  );

  const globalProduct = RolesWithoutProduct.includes(user?.role as UserRoleID)
    ? product
    : user?.product || product;

  const renderBaseOnProduct = (
    carRoutes: IRoutes[],
    healthRoutes: IRoutes[],
    travelRoutes: IRoutes[]
  ) => {
    if (globalProduct === PRODUCTS.CAR_PRODUCT_INSURANCE) {
      return carRoutes;
    }
    if (globalProduct === PRODUCTS.TRAVEL_PRODUCT_INSURANCE) {
      return travelRoutes;
    }
    if (globalProduct === PRODUCTS.HEALTH_PRODUCT_INSURANCE) {
      return healthRoutes;
    }
    return [];
  };

  const baseProtectedRoutes = [
    ...accountRoutes,
    ...dashboardRoutes,
    ...renderBaseOnProduct(leadRoutes, [healthRoutes], []),
    ...customerRoutes,
    ...renderBaseOnProduct(
      orderRoutes,
      [healthOrdersRoutes],
      [travelOrdersRoutes]
    ),
    ...permissionDenied,
    ...renderBaseOnProduct(packageRoutes, [healthPackageRoutes], []),
    ...carDiscountRoutes,
    ...customerProfileRoutes,
    ...cancellationRoutes,
    ...curatedCarRoutes,
    ...renderBaseOnProduct(discountRoutes, [healthDiscountRoutes], []),
    ...renderBaseOnProduct(carePayRoutes, [healthCarePayRoutes], []),
    ...accountingRoutes,
  ];

  const protectedRoutes = flattenRoutes(baseProtectedRoutes, []);
  const publicRoutes = flattenRoutes(authRoutes, [], true);
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((route: IRoutes) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<Loader />}>
                <PublicRoute
                  component={route.component}
                  layout={route.layout}
                />
              </Suspense>
            }
          />
        ))}
        {protectedRoutes.map((route: IRoutes) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute
                  component={route.component}
                  layout={route.layout}
                  permission={route.permission}
                  path={route.path}
                />
              </Suspense>
            }
          />
        ))}

        <Route
          path="*"
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
