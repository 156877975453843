const isCanCreateLead = (useRole: string) => {
  const rolesCreateLead = ['roles/admin', 'roles/backoffice-supervisor'];
  return rolesCreateLead.includes(useRole);
};

const canDownload = (userRole: string) => {
  const rolesCreateLead = ['roles/admin', 'roles/backoffice-supervisor'];
  return rolesCreateLead.includes(userRole);
};

const healthLeadImportColumns = [
  'First Name',
  'Last Name',
  'Gender',
  'Date of birth',
  'Phone',
  'Email',
  'Policy Start date',
];

const healthLeadImportColumnsWithType = [
  { name: 'First Name', dataType: 'string' },
  { name: 'Last Name', dataType: 'string' },
  { name: 'Gender', dataType: 'gender' },
  { name: 'Date of birth', dataType: 'date' },
  { name: 'Phone', dataType: 'number' },
  { name: 'Email', dataType: 'email' },
  { name: 'Policy Start date', dataType: 'date' },
];

const healthLeadImportRequiredColumns = ['First Name', 'Last Name', 'Phone'];
const healthLeadImportShouldNotHaveColumns = ['Redbook ID'];

const healthLeadImportMaximumLimit = 10000;

export {
  isCanCreateLead,
  canDownload,
  healthLeadImportColumns,
  healthLeadImportColumnsWithType,
  healthLeadImportRequiredColumns,
  healthLeadImportShouldNotHaveColumns,
  healthLeadImportMaximumLimit,
};
