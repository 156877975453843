const ProductOptions = [
  {
    id: 1,
    value: 'products/car-insurance',
    title: 'productionOptions.carInsurance',
  },
  {
    id: 2,
    value: 'products/health-insurance',
    title: 'productionOptions.healthInsurance',
  },
  {
    id: 3,
    value: 'products/travel-insurance',
    title: 'productionOptions.travelInsurance',
  },
];

export default ProductOptions;
